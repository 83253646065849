import { apiRequest } from 'api'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { LotesProcessados } from 'store/dadosProcessados/types'
import { LotesProcessadosQueryParams, Saida, SaidaQueryParams } from './types'

export const getSaida = async (params: SaidaQueryParams, token: string): Promise<Saida[]> => {
  try {
    return apiRequest<Saida[]>('/v1/ds/saidas', token, params)
    // RETURN MOCK DATA
    // return saidas
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/saidas/get', error)
  }
}

export const getLotesProcessados = async (
  params: LotesProcessadosQueryParams,
  token: string
): Promise<LotesProcessados> => {
  try {
    // return lotesProcessados
    const data = await apiRequest<{ lotesProcessados: LotesProcessados }>(
      '/v1/lotes/lotesprocessados',
      token,
      params
    )
    return data.lotesProcessados
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotesprocessados/get', error)
  }
}
