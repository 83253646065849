import _ from 'lodash'
import { LoteAcompanhamentoDiario } from 'store/dadosProcessados/types.d'
import { MediaPorCategoria, Genders, Media, Themes, DadoDiario } from 'store/main/types.d'
import { ChartData, Axis } from './types.d'
import colors, { Colors } from 'styles/variables/colors'
import { LineChartOptions } from 'components/charts/LineChart/types'
import FormatDate from 'helpers/FormatDate'
import moment from 'moment'
import { PlanejamentoSemanalTotais } from 'store/planejamento/types'
import { GrouppedPlanejamentoSemanal } from 'store/planejamentoSemanal/types'

export const getChartOptions = (
  colors?: Array<string>,
  yaxisTitle?: string,
  xAxisTitle?: string
): LineChartOptions => {
  const options: LineChartOptions = {
    chart: {
      id: 'lines-chart',
      height: 350
      // zoom: {
      //   enabled: true
      // }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: true,
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.3
      }
    },
    yaxis: {
      title: {
        text: yaxisTitle || ''
      }
    },
    xaxis: {
      title: {
        text: xAxisTitle || ''
      }
    },
    stroke: {
      width: [3, 3, 3, 3]
    },
    colors: colors || []
  }
  return options
}

const getDataColor = (data: string, theme: Themes): string => {
  switch (data) {
    case Genders.m:
      return colors(theme, Colors.redDark)
    case Genders.f:
      return colors(theme, Colors.lightOrange)
    case Genders.x:
      return colors(theme, Colors.accent)
    default:
      return colors(theme, Colors.redDark)
  }
}

export const getMediaData = (data: MediaPorCategoria, theme: Themes): ChartData => {
  const axisValues: any = []
  const keys = Object.keys(data)
  let colors: Array<string> = []
  for (const key of keys) {
    if (key === Genders.m || key === Genders.f || key === Genders.x) {
      const chartData: Axis = { name: '', data: [] }
      chartData.name = key.toString()
      data[key]?.map((mediaPorDia: Media) => {
        colors.push(getDataColor(mediaPorDia.genero, theme))
        if (mediaPorDia.dia && mediaPorDia?.valor) {
          chartData.data.push({ x: mediaPorDia.dia, y: Number(mediaPorDia?.valor?.toFixed(2)) })
        }
      })
      axisValues.push(chartData)
    }
  }
  colors = _.uniq(colors)

  return {
    axisValues,
    colors
  }
}

export const getDailyData = (data: Array<LoteAcompanhamentoDiario> | undefined): ChartData => {
  const axisValues: any = []
  data?.map((m: LoteAcompanhamentoDiario) => {
    const keys = Object.keys(m)
    for (const key of keys) {
      const chartData: Axis = { name: '', data: [] }
      chartData.name = key.toString()
      m[key].map((dadoDiario: DadoDiario) => {
        chartData.data.push({ y: dadoDiario.valor, x: dadoDiario.dia })
      })
      axisValues.push(chartData)
    }
  })
  return {
    axisValues
  }
}
