export type PlanejamentoSemanalStore = {
  alojamentosAtivos?: AlojamentoResumo[]
  filteredAlojamentosAtivos: AlojamentoResumo[]
  alojamentosAtivosLoading: boolean
  dataInicioPlanejamento: date
  dataFimPlanejamento: date
  primeiraSemanaInicio: date
  primeiraSemanaFim: date
  segundaSemanaInicio: date
  segundaSemanaFim: date
  terceiraSemanaInicio: date
  terceiraSemanaFim: date
  quartaSemanaInicio: date
  quartaSemanaFim: date
  primeiraSemana: PlanejamentoSemanal[]
  segundaSemana: PlanejamentoSemanal[]
  terceiraSemana: PlanejamentoSemanal[]
  quartaSemana: PlanejamentoSemanal[]
  planejamentosSemanais?: PlanejamentoSemanal[]
  planejamentoSemanalSummary: PlanejamentoSemanalSummary[]
  planejamentoSemanalTotais: PlanejamentoSemanalTotais[]
  planejamentosSemanaisLoading: boolean
}

export type WeeklyPlanningTotals = Pick<
  PlanejamentoSemanal,
  | 'origemDado'
  | 'idIntegrador'
  | 'pesoPredito'
  | 'idadeSaida'
  | 'animaisPreditos'
  | 'consumo'
  | 'lucro'
>

export type PlanejamentoSemanalTotais = {
  idIntegrador?: string
  lotesTotal?: number
  pesoPreditoMedio?: number
  idadeSaidaMedia?: number
  animaisPreditosTotal?: number
  consumoMedio?: number
  lucroTotal?: number
  mediaAbate?: number
}

export type PlanejamentoSemanalSummary = {
  id: number
  idIntegrador?: string
  data?: string
  diaSemana?: weekDay
  pesoPredito?: number
  idadeSaida?: number
  animaisPreditos?: number
  consumo?: number
  lucro?: number
}

export type AlojamentoResumo = {
  id: string
  clientId: string
  movementType?: MovementTypeEnum
  origemDado?: OrigemDadoEnum
  idIntegrador: string
  idIntegradorDestino: string
  idGranja: number
  idNucleo: string
  numeroNucleo: number
  idGalpao: string
  numeroGalpao: number
  nroLote: number
  sexo?: string
  uf?: string
  municipio?: string
  distanciaAbatedouroNV?: number
  distanciaAbatedouroITA?: number
  pesoMedio?: number
  dataAlojamento: date
  dataNascimento: date
  idade?: number
  dataIdade?: date
  qtdAvesAlojadas?: number
  mortalidade?: number
  saldo?: number
  idadeSaida?: number
  animaisPreditos?: number
  pesoPredito?: number
  lucro?: number
  consumo?: number
  custo?: number
  predicao?: PredictionLoteData[]
}

export type PredictionLoteData = {
  pesoPredito?: number
  idadePredita?: number
  mortalidadePredita?: number
  nroAvesPreditas?: number
  lucroPredito?: number
  consumoPredito?: number
}

export enum OrigemDadoEnum {
  total = 'total',
  alojamento = 'alojamento',
  terceiraSemana = 'terceiraSemana',
  quartaSemana = 'quartaSemana'
}

export enum MovementTypeEnum {
  inTheWeek = 1,
  fromHousings = 2,
  removedFromPlanning = 3,
  fromIntegrator = 4
}

export type PlanejamentoSemanal = {
  id: number
  clientId: string
  movementType?: MovementTypeEnum
  origemDado?: OrigemDadoEnum
  idIntegrador: string
  idIntegradorDestino: string
  dataAlojamento: date
  idade: number
  dataSaida: date
  dataSaidaFormatada?: string
  dataSaidaFinal?: date
  idGranja: number
  idNucleo: string
  numeroNucleo: number
  idGalpao: string
  numeroGalpao: number
  nroLote: number
  sexo?: string
  uf?: string
  municipio?: string
  distanciaAbatedouroNV?: number
  distanciaAbatedouroITA?: number
  idadeSaida?: number
  animaisPreditos?: number
  pesoPredito?: number
  lucro?: number
  consumo?: number
  custo?: number
}

export type NovoPlanejamentoSemanal = {
  id: number
  idIntegrador: string
  idadeSaida: number
  dataSaida: date
  idGalpao: string
  nroLote: number
  animaisPreditos?: number
  pesoPredito?: number
  lucro?: number
  consumo?: number
  custo?: number
}

export enum PlanejamentoSemanalConstants {
  ACTION_GET_ALOJAMENTOS_ATIVOS = 'ACTION_GET_ALOJAMENTOS_ATIVOS',
  ACTION_GET_PLANEJAMENTOS_SEMANAIS = 'ACTION_GET_PLANEJAMENTOS_SEMANAIS',
  ACTION_GET_PLANEJAMENTO_SEMANAL_TOTAIS = 'ACTION_GET_PLANEJAMENTO_SEMANAL_TOTAIS',
  ACTION_GET_PLANEJAMENTO_SEMANAL_SUMMARY = 'ACTION_GET_PLANEJAMENTO_SEMANAL_SUMMARY',
  ACTION_FILTER_ALOJAMENTOS_BY_PLANEJAMENTOS = 'ACTION_FILTER_ALOJAMENTOS_BY_PLANEJAMENTOS',
  REDUCER_CLEAR_PLANEJAMENTO_SEMANAL_DATA = 'REDUCER_CLEAR_PLANEJAMENTO_SEMANAL_DATA',
  REDUCER_SET_PLANEJAMENTO_SEMANAL_DATA = 'REDUCER_SET_PLANEJAMENTO_SEMANAL_DATA',
  ACTION_SAVE_PLANEJAMENTO_SEMANAL = 'ACTION_SAVE_PLANEJAMENTO_SEMANAL',
  ACTION_DELETE_PLANEJAMENTO_SEMANAL = 'ACTION_DELETE_PLANEJAMENTO_SEMANAL'
}
