import { Credentials } from 'api/auth/types.d'
import { StorageKeys } from './types.d'
import { Themes, Locales, UnitySystem, ColumnsCollapse } from 'store/main/types.d'

export const setItemInStorage = (
  key: StorageKeys,
  value?: Credentials | Themes | Locales | ColumnsCollapse | string | null
): void => {
  if (!value) {
    localStorage.removeItem(key)
    return
  }
  localStorage.setItem(key, JSON.stringify(value))
}

export const getItemFromStorage = (
  key: StorageKeys
): Credentials | Themes | Locales | UnitySystem | ColumnsCollapse | undefined => {
  // const colCollapse = ['order', 'id', 'collapse', 'columns', 'label']
  // const col = ['order', 'id', 'active', 'disablePadding', 'type', 'label']
  switch (key) {
    case StorageKeys.Credentials: {
      const credentialsJSONStr = localStorage.getItem(StorageKeys.Credentials)
      const credentials = credentialsJSONStr ? JSON.parse(credentialsJSONStr) : undefined
      return credentials
    }
    case StorageKeys.Theme: {
      const themeJSONStr = localStorage.getItem(StorageKeys.Theme)
      if (!themeJSONStr) {
        setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
        return Themes.LIGHT
      }
      const theme: Themes = JSON.parse(themeJSONStr)
      if (theme !== Themes.LIGHT && theme !== Themes.DARK) {
        setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
        return Themes.LIGHT
      }
      return theme
    }
    case StorageKeys.Locale: {
      const localeJSONStr = localStorage.getItem(StorageKeys.Locale)
      if (!localeJSONStr) {
        setItemInStorage(StorageKeys.Locale, Locales.pt)
        return Locales.pt
      }
      const locale: Locales = JSON.parse(localeJSONStr)
      if (locale !== Locales.en && locale !== Locales.pt) {
        setItemInStorage(StorageKeys.Locale, Locales.pt)
        return Locales.pt
      }
      return locale
    }
    case StorageKeys.UnitySystem: {
      const unitySystemJSONStr = localStorage.getItem(StorageKeys.UnitySystem)
      if (!unitySystemJSONStr) {
        setItemInStorage(StorageKeys.UnitySystem, UnitySystem.metric)
        return UnitySystem.metric
      }
      const unitySystem: UnitySystem = JSON.parse(unitySystemJSONStr)
      if (unitySystem !== UnitySystem.imperial && unitySystem !== UnitySystem.metric) {
        setItemInStorage(StorageKeys.UnitySystem, UnitySystem.metric)
        return UnitySystem.metric
      }
      return unitySystem
    }
    default:
      return
  }
}
