import { call, Effect, put, PutEffect } from 'redux-saga/effects'
import Exception from '../../helpers/Exception'

// Methods
import { workerSignOut } from 'store/auth/sagas'
import { getLotesProcessados } from 'api/dadosProcessados'

// types
import { DadosProcessadosConstants, LotesProcessados } from './types.d'
import store from '../index'
import Codes from 'helpers/Codes'
import { MainConstants } from 'store/main/types.d'

function* putDadosProcessadosData(payload: any): Generator<
  PutEffect<{
    type: DadosProcessadosConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: DadosProcessadosConstants.REDUCER_SET_DADOS_PROCESSADOS_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetLotesProcessados(action: Effect): any {
  const { params } = action.payload
  try {
    //if (!params.lotesEscalados)
    //yield call(putDadosProcessadosData, { lotesAtivosProcessadosLoading: true })
    //else if (params.lotesEscalados)
    yield call(putDadosProcessadosData, { lotesEscaladosProcessadosLoading: true })

    const { credentials } = store.getState().auth

    const lotesProcessados: LotesProcessados = yield call(
      getLotesProcessados,
      params,
      credentials.token
    )
    //if (!params.lotesEscalados) {
    //   yield call(putDadosProcessadosData, {
    //     lotesAtivosProcessados: lotesProcessados,
    //     lotesAtivosProcessadosLoading: false
    //   })
    // } else if (params.lotesEscalados) {
    yield call(putDadosProcessadosData, {
      lotesEscaladosProcessados: lotesProcessados,
      lotesEscaladosProcessadosLoading: false
    })
    // }
  } catch (error: any) {
    console.log(error)
    if (error.additionalInfo?.response?.status === 401 || error?.response?.status === 401)
      yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/processados/sagas/workerGetDadosProcessadoss',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putDadosProcessadosData, {
      lotesAtivosProcessadosLoading: false,
      lotesEscaladosProcessadosLoading: false
    })
    yield call(putMainData, { message })
  }
}
