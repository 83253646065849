import { put, call, Effect, PutEffect } from 'redux-saga/effects'

// Constants
import Codes from 'helpers/Codes'

// Types
import { AuthConstants } from './types.d'
import { StorageKeys } from 'storage/types.d'
import { AlertModalTypes, MainConstants } from 'store/main/types.d'
import { MessageType, MessageAlertMode } from 'helpers/Message/types.d'
import store from '../index'

// Classes
import Message from 'helpers/Message'
import Exception from 'helpers/Exception'
import i18next from 'i18next'

// Methods
import { getItemFromStorage, setItemInStorage } from 'storage'

// API
import { authenticate, updatePassword } from 'api/auth'
import { Credentials } from 'api/auth/types.d'
import { IntegradorConstants } from 'store/integrador/types.d'
import { ParametrosPOConstants } from 'store/parametrosPO/types.d'
import { DadosProcessadosConstants } from 'store/dadosProcessados/types.d'
import { GranjaConstants } from 'store/granjas/types.d'
import { LoteConstants } from 'store/lotes/types.d'
import { SummaryConstants } from 'store/summary/types'
import { AnaliseCrescimentoConstants } from 'store/analiseCrescimento/types.d'
import { PlanejamentoSemanalConstants } from 'store/planejamentoSemanal/types.d'

function* putAuthData(payload: any): Generator<
  PutEffect<{
    type: AuthConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: AuthConstants.REDUCER_SET_AUTH_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerSignIn(action: Effect): any {
  yield call(putAuthData, { isAuthenticating: true })

  try {
    const { body } = action.payload
    const credentials: Credentials = { token: '' }
    credentials.token = yield call(authenticate, body)

    setItemInStorage(StorageKeys.Credentials, credentials)
    yield call(putAuthData, { credentials, isAuthenticating: false, isAuthenticated: true })
  } catch (error: any) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
    } else {
      message = new Message(
        MessageType.ERROR,
        'title',
        'unexpected_error',
        MessageAlertMode.INNER,
        'workerSignIn'
      )
    }
    yield call(putAuthData, { isAuthenticating: false })
    yield call(putMainData, { message })
  }
}

export function* workerCheckAuthentication(action?: Effect): any {
  const checkingFromRouter = action?.payload.checkingFromRouter
  if (checkingFromRouter) {
    yield call(putAuthData, {
      isCheckingAuthentication: true
    })
  }
  try {
    const credentials = getItemFromStorage(StorageKeys.Credentials) as Credentials
    yield call(putAuthData, {
      credentials: credentials,
      isAuthenticated: !!credentials,
      isAuthenticating: false,
      isCheckingAuthentication: false
    })
  } catch (error: any) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      if (error.code === Codes.HTTP.UNAUTHORIZED_401) {
        setItemInStorage(StorageKeys.Credentials)
        setItemInStorage(StorageKeys.RefreshToken)
        message = new Exception(
          Codes.UserInteraction.SIGN_IN_AGAIN,
          'workerCheckAuthentication',
          ''
        ).getMessage()
      }
    } else {
      message = new Exception(
        Codes.Internals.UNEXPECTED_ERROR,
        'sagas/auth/sagas/workerCheckAuthentication',
        error
      ).getMessage()
    }
    yield call(putMainData, {
      message
    })
    if (checkingFromRouter) {
      yield call(putAuthData, {
        isAuthenticating: false,
        isAuthenticated: false,
        isCheckingAuthentication: false
      })
    }
  }
}

export function* workerUpdatePassword(action: Effect): any {
  const { data } = action.payload

  try {
    yield call(putAuthData, { updatePasswordLoading: true })
    const { credentials } = store.getState().auth

    const response = yield call(updatePassword, credentials.token, data)
    yield call(putAuthData, { updatePasswordLoading: false })
    const message =
      response?.toLowerCase() === 'updated'
        ? i18next.t('message:password_updated')
        : i18next.t('message:error_password_updated')
    const type =
      response?.toLowerCase() === 'updated' ? AlertModalTypes.success : AlertModalTypes.error
    yield call(putMainData, {
      infoModal: {
        message,
        open: true,
        type
      }
    })
  } catch (error: any) {
    console.log(error)
    if (error.additionalInfo?.response?.status === 401 || error?.response?.status === 401)
      yield call(workerSignOut)
    if (error.additionalInfo?.response?.status === 403) {
      yield call(putMainData, {
        infoModal: {
          message: i18next.t('message:wrong_old_password'),
          open: true,
          type: AlertModalTypes.error
        }
      })
    }
    yield call(putAuthData, { updatePasswordLoading: false })
  }
}

export function* workerSignOut(action?: Effect): any {
  try {
    setItemInStorage(StorageKeys.Credentials)
    yield put({
      type: AuthConstants.REDUCER_CLEAR_AUTH_DATA
    })
    yield put({
      type: MainConstants.REDUCER_CLEAR_MAIN_DATA
    })
    yield put({
      type: IntegradorConstants.REDUCER_CLEAR_INTEGRADOR_DATA
    })
    yield put({
      type: ParametrosPOConstants.REDUCER_CLEAR_PARAMETROSPO_DATA
    })
    yield put({
      type: DadosProcessadosConstants.REDUCER_CLEAR_DADOS_PROCESSADOS_DATA
    })
    yield put({
      type: GranjaConstants.REDUCER_CLEAR_GRANJA_DATA
    })
    yield put({
      type: LoteConstants.REDUCER_CLEAR_LOTE_DATA
    })
    yield put({
      type: SummaryConstants.REDUCER_CLEAR_SUMMARY_DATA
    })
    yield put({
      type: AnaliseCrescimentoConstants.REDUCER_CLEAR_ANALISE_CRESCIMENTO_DATA
    })
    yield put({
      type: PlanejamentoSemanalConstants.REDUCER_CLEAR_PLANEJAMENTO_SEMANAL_DATA
    })
    const { logout } = action?.payload
    if (!logout) {
      yield call(putMainData, {
        payload: {
          message: new Message(
            MessageType.ERROR,
            '',
            'signin_again',
            MessageAlertMode.INNER,
            'signout'
          )
        }
      })
    }
  } catch (error: any) {
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/auth/sagas/workerSignOut',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putMainData, {
      message
    })
  }
}
