import {
  NovoPlanejamentoSemanal,
  PlanejamentoSemanal,
  PlanejamentoSemanalConstants
} from './types.d'

export const getAlojamentosAtivos = () => ({
  type: PlanejamentoSemanalConstants.ACTION_GET_ALOJAMENTOS_ATIVOS
})

export const getPlanejamentosSemanais = () => ({
  type: PlanejamentoSemanalConstants.ACTION_GET_PLANEJAMENTOS_SEMANAIS
})

export const getPlanejamentoSemanalTotais = (data: PlanejamentoSemanal[]) => ({
  type: PlanejamentoSemanalConstants.ACTION_GET_PLANEJAMENTO_SEMANAL_TOTAIS,
  payload: { data }
})

export const getPlanejamentoSemanalSummary = (data: PlanejamentoSemanal[]) => ({
  type: PlanejamentoSemanalConstants.ACTION_GET_PLANEJAMENTO_SEMANAL_SUMMARY,
  payload: { data }
})

export const filterAlojamentosByPlanejamento = () => ({
  type: PlanejamentoSemanalConstants.ACTION_FILTER_ALOJAMENTOS_BY_PLANEJAMENTOS
})

export const savePlanejamentosSemanal = (data: NovoPlanejamentoSemanal[]) => ({
  type: PlanejamentoSemanalConstants.ACTION_SAVE_PLANEJAMENTO_SEMANAL,
  payload: { data }
})

export const deletePlanejamentoSemanal = (planejamentosSemanaisIds: number[]) => ({
  type: PlanejamentoSemanalConstants.ACTION_DELETE_PLANEJAMENTO_SEMANAL,
  payload: planejamentosSemanaisIds
})
