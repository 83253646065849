import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { PutEffect, put, call, Effect } from 'redux-saga/effects'
import store from 'store'
import { workerSignOut } from 'store/auth/sagas'
import { MainConstants, weekDay } from 'store/main/types.d'
import {
  AlojamentoResumo,
  OrigemDadoEnum,
  PlanejamentoSemanal,
  PlanejamentoSemanalConstants,
  PlanejamentoSemanalSummary,
  PlanejamentoSemanalTotais
} from './types.d'
import { getLotesAlojamentosResumo } from 'api/lote'
import { SaidaQueryParams } from 'api/planningV2/types.ts'
import { deletePlanejamentoSemanal, getSaidas, postPlanejamentoSemanal } from 'api/planningV2'
import moment from 'moment'
import _ from 'lodash'
import { IntegratorEnum } from 'store/integrador/types.d'
import i18next from 'i18next'

export function* putPlanejamentoSemanalData(payload: unknown): Generator<
  PutEffect<{
    type: PlanejamentoSemanalConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: PlanejamentoSemanalConstants.REDUCER_SET_PLANEJAMENTO_SEMANAL_DATA,
    payload
  })
}

export function* putMainData(payload: unknown): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetAlojamentosAtivos(): any {
  try {
    yield call(putPlanejamentoSemanalData, { alojamentosAtivosLoading: true })

    const { credentials } = store.getState().auth

    const params = {
      lotesAtivos: true
    }

    let alojamentosAtivos: AlojamentoResumo[] = yield call(
      getLotesAlojamentosResumo,
      params,
      credentials.token
    )

    alojamentosAtivos = alojamentosAtivos.map((aa) => ({
      ...aa,
      dataAlojamento: moment(aa.dataAlojamento).format('YYYY-MM-DD'),
      color: undefined,
      idIntegradorDestino: aa.idIntegrador,
      origemDado: OrigemDadoEnum.alojamento
    }))

    yield call(putPlanejamentoSemanalData, {
      alojamentosAtivos,
      alojamentosAtivosLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/alojamentosAtivos/workerGetAlojamentosAtivos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoSemanalData, {
      alojamentosAtivos: [],
      alojamentosAtivosLoading: false
    })
    yield call(putMainData, { message })
  }
}

export function* workerGetPlanejamentosSemanais(): any {
  try {
    yield call(putPlanejamentoSemanalData, { planejamentosSemanaisLoading: true })

    const { credentials } = store.getState().auth
    const {
      dataInicioPlanejamento,
      dataFimPlanejamento,
      primeiraSemanaInicio,
      primeiraSemanaFim,
      segundaSemanaInicio,
      segundaSemanaFim,
      terceiraSemanaInicio,
      terceiraSemanaFim,
      quartaSemanaInicio,
      quartaSemanaFim
    } = store.getState().planejamentoSemanal

    const params: SaidaQueryParams = {
      inicio: dataInicioPlanejamento,
      fim: dataFimPlanejamento
    }

    let planejamentosSemanais: PlanejamentoSemanal[] = yield call(
      getSaidas,
      params,
      credentials.token
    )

    planejamentosSemanais = planejamentosSemanais.map((ps) => ({
      ...ps,
      idIntegradorDestino: ps?.idIntegrador,
      dataSaidaFormatada: moment(ps?.dataSaidaFormatada).format('YYYY-MM-DD'),
      movementType: undefined
    }))

    const primeiraSemana = planejamentosSemanais.filter(
      (ps) =>
        ps.dataSaida >= primeiraSemanaInicio &&
        moment(ps.dataSaida).format('YYYY-MM-DD') <= primeiraSemanaFim
    )
    const segundaSemana = planejamentosSemanais.filter(
      (ps) =>
        ps.dataSaida >= segundaSemanaInicio &&
        moment(ps.dataSaida).format('YYYY-MM-DD') <= segundaSemanaFim
    )
    let terceiraSemana = planejamentosSemanais.filter(
      (ps) =>
        ps.dataSaida >= terceiraSemanaInicio &&
        moment(ps.dataSaida).format('YYYY-MM-DD') <= terceiraSemanaFim
    )
    let quartaSemana = planejamentosSemanais.filter(
      (ps) =>
        ps.dataSaida >= quartaSemanaInicio &&
        moment(ps.dataSaida).format('YYYY-MM-DD') <= quartaSemanaFim
    )

    terceiraSemana = terceiraSemana.map((ps) => ({
      ...ps,
      origemDado: OrigemDadoEnum.terceiraSemana
    }))
    quartaSemana = quartaSemana.map((ps) => ({ ...ps, origemDado: OrigemDadoEnum.quartaSemana }))

    yield call(putPlanejamentoSemanalData, {
      primeiraSemana,
      segundaSemana,
      terceiraSemana: [...terceiraSemana],
      quartaSemana: [...quartaSemana],
      planejamentosSemanais,
      planejamentosSemanaisLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamentoSemanal/workerGetPlanejamentosSemanais',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoSemanalData, {
      planejamentosSemanaisLoading: false,
      planejamentosSemanais: []
    })
    yield call(putMainData, { message })
  }
}

function getWeekTotals(data: PlanejamentoSemanal[]) {
  if (data.length) {
    const summary: PlanejamentoSemanalSummary[] = []
    let averageWeight = 0
    let averageAge = 0
    let averageConsumption = 0
    let idIntegrator = ''
    let count = 0

    const dataGrouppedByDay = _.groupBy(data, 'dataSaidaFormatada')

    for (const k in dataGrouppedByDay) {
      dataGrouppedByDay[k]?.map((fw: PlanejamentoSemanal) => {
        if (fw.pesoPredito && fw.animaisPreditos)
          averageWeight += fw.pesoPredito * fw.animaisPreditos
        if (fw.idadeSaida && fw.animaisPreditos) averageAge += fw.idadeSaida * fw.animaisPreditos
        if (fw.consumo && fw.animaisPreditos) averageConsumption += fw.consumo * fw.animaisPreditos
        idIntegrator = fw.idIntegrador
      })
      const predictedAnimalsTotal = _.sumBy(dataGrouppedByDay[k], 'animaisPreditos')
      const predictedProfit = _.sumBy(dataGrouppedByDay[k], 'lucro')
      averageWeight = averageWeight / predictedAnimalsTotal
      averageAge = averageAge / predictedAnimalsTotal
      averageConsumption = averageConsumption / predictedAnimalsTotal

      const weekdayNumber = moment(k).weekday()
      const weekday = weekDay[weekdayNumber]?.label
      const weekDayLabel = weekday ? i18next.t(weekday) : null

      summary.push({
        id: count++,
        idIntegrador: idIntegrator,
        data: k,
        diaSemana: weekDayLabel,
        pesoPredito: Number(averageWeight?.toFixed(3)),
        idadeSaida: Number(averageAge?.toFixed()),
        animaisPreditos: Number(predictedAnimalsTotal),
        consumo: Number(averageConsumption?.toFixed(3)),
        lucro: Number(predictedProfit?.toFixed(2))
      })
    }
    return summary
  }
}

export function* workerGetPlanejamentoSemanalTotals(action: Effect): any {
  const { data } = action.payload
  if (data) {
    const planejamentoSemanalTotais: PlanejamentoSemanalTotais[] = []

    for (const key in IntegratorEnum) {
      const dataFilteredByIntegrator = data?.filter(
        (d: PlanejamentoSemanal) => d.idIntegrador === key
      )
      if (dataFilteredByIntegrator?.length) {
        let pesoPreditoMedio = 0
        let idadeSaidaMedia = 0
        let animaisPreditosTotal = 0
        let consumoMedio = 0
        let lucroTotal = 0
        dataFilteredByIntegrator?.map((df: PlanejamentoSemanal) => {
          pesoPreditoMedio += (df.pesoPredito || 0) * (df.animaisPreditos || 0)
          idadeSaidaMedia += (df.idadeSaida || 0) * (df.animaisPreditos || 0)
          animaisPreditosTotal += df?.animaisPreditos || 0
          consumoMedio += (df.consumo || 0) * (df.animaisPreditos || 0)
          lucroTotal += df?.lucro || 0
        })
        pesoPreditoMedio = pesoPreditoMedio / animaisPreditosTotal
        idadeSaidaMedia = idadeSaidaMedia / animaisPreditosTotal
        consumoMedio = consumoMedio / animaisPreditosTotal
        const grouppedData = _.groupBy(dataFilteredByIntegrator, 'dataSaidaFormatada')
        let days
        if (grouppedData) days = Object.keys(grouppedData).length
        const mediaAbate = days ? animaisPreditosTotal / days : 0
        planejamentoSemanalTotais.push({
          idIntegrador: key,
          lotesTotal: dataFilteredByIntegrator.length,
          pesoPreditoMedio: Number(pesoPreditoMedio?.toFixed(3)),
          idadeSaidaMedia: Number(idadeSaidaMedia.toFixed()),
          animaisPreditosTotal,
          consumoMedio: Number(consumoMedio?.toFixed(3)),
          lucroTotal,
          mediaAbate: Number(mediaAbate.toFixed())
        })
      }
    }
    yield call(putPlanejamentoSemanalData, {
      planejamentoSemanalTotais
    })
  }
}

export function* workerGetPlanejamentoSemanalSummary(action: Effect): any {
  const { data } = action.payload
  if (data) {
    const summaryTableData: PlanejamentoSemanalSummary[] = []

    for (const key in IntegratorEnum) {
      const dataFilteredByIntegrator = data?.filter(
        (d: PlanejamentoSemanal) => d.idIntegrador === key
      )
      if (dataFilteredByIntegrator?.length) {
        const summaryData = getWeekTotals(dataFilteredByIntegrator)
        if (summaryData) summaryTableData.push(...summaryData)
      }
    }
    yield call(putPlanejamentoSemanalData, {
      planejamentoSemanalSummary: summaryTableData
    })
  }
}

// REMOVE LOTES FROM HOUSINGS THAT ARE ON PLANNING
export function* workerFilterAlojamentosByPlanejamentos(): any {
  try {
    yield call(putPlanejamentoSemanalData, { alojamentosAtivosLoading: true })

    const { alojamentosAtivos, planejamentosSemanais } = store.getState().planejamentoSemanal

    const lotes = planejamentosSemanais.map((ps: PlanejamentoSemanal) => ps.nroLote)

    const alojamentos = alojamentosAtivos.filter((aa: AlojamentoResumo) => {
      const encontrou = lotes.find((l: number) => l === aa.nroLote)
      // "!" para negar, ou seja, filtrar apenas os que não foram encontrados
      return !encontrou
    })

    yield call(putPlanejamentoSemanalData, {
      filteredAlojamentosAtivos: alojamentos,
      alojamentosAtivosLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamentoSemanal/workerFilterAlojamentosByPlanejamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoSemanalData, { alojamentosAtivosLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSavePlanejamentoSemanal(action: Effect): any {
  const { data } = action.payload
  try {
    yield call(putPlanejamentoSemanalData, { planejamentosSemanaisLoading: true })

    const { credentials } = store.getState().auth
    yield call(postPlanejamentoSemanal, credentials.token, data)

    yield call(putPlanejamentoSemanalData, {
      planejamentosSemanaisLoading: false,
      primeiraSemana: [],
      segundaSemana: [],
      alojamentosAtivos: undefined,
      filteredAlojamentosAtivos: [],
      planejamentosSemanais: undefined
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamentoSemanal/workerSavePlanejamentoSemanal',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoSemanalData, { planejamentosSemanaisLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerDeletePlanejamentoSemanal(action: Effect): any {
  const data = action.payload
  try {
    yield call(putPlanejamentoSemanalData, { planejamentosSemanaisLoading: true })

    const { credentials } = store.getState().auth
    yield call(deletePlanejamentoSemanal, credentials.token, { ids: data })

    yield call(putPlanejamentoSemanalData, {
      planejamentosSemanaisLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamentoSemanal/workerDeletePlanejamentoSemanal',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoSemanalData, { planejamentosSemanaisLoading: false })
    yield call(putMainData, { message })
  }
}
